.main-wrapper {
	display: grid;
	grid-template-rows: max-content auto max-content;
	width: 100vw;
}

.content-wrapper {
	display: grid;
	grid-template-columns: max-content 1fr;
	width: 100%;
}

.main-content {
	padding: 2rem;
	width: 100%;
	.welcome-jumbotron {
		padding: 30px;
		// margin-top: -72px;
		background-image: linear-gradient(180deg, $primary, $secondary);
		//   background-color: $primary;
		padding-top: 70px;
		color: #fff;
		padding-bottom: 100px;
	}
	.main-content-inner {
		padding: 1rem;
		min-height: 85vh;
	}
}

// .full-width {
// 	padding-top: 30px;
// 	margin-bottom: 30px;
// }

// Dashboard
.dashboard-stats {
	margin-top: -84px;
}
.dashboard-card {
	background-color: $white;
	padding: 20px;
	box-shadow: $box-shadow;
	border-radius: 8px;
	h5 {
		font-weight: 400;
		color: $secondary;
	}
	h2 {
		font-weight: 600;
		color: $primary;
	}
}

.dashboard {
	height: max-content;
	.dashboard-box-wrapper {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1rem;

		.dashboard-box {
			background-color: #f8f9fa;
			padding: 1rem 0.5rem;
			border-radius: 5px;
			h1 {
				margin-top: 1rem;
			}
		}
	}
	.course-flex {
		display: flex;
		flex: 1;
		gap: 2rem;
		// margin: 1rem 0;
		width: max-content;
		// padding: 1rem;
	}
	.dashboard-progress {
		margin-top: 2rem;
		// display: grid;
		// grid-template-columns: 1fr 1fr;
		height: max-content;
		// gap: 2rem;
		.course_wrapper {
			height: 60vh;
			overflow-y: scroll;
			padding: 1rem;
		}
		.course-progress {
			padding: 0.5rem 1rem;
			box-shadow: 0px 6px 30px rgba(64, 83, 145, 0.25);
			border-radius: 5px;
			margin-bottom: 1rem;
			.progress-bar {
				background-color: #00c48c;
			}
		}
	}
	.thematic_areas {
		.scroll {
			height: auto;
			max-height: 50vh;
		}
	}
}

.dashboard-setting {
	.setting-nav {
		margin-bottom: 4rem;

		p {
			color: #828282;
			border-bottom: 4px solid transparent;
		}
		.active {
			color: #333333;
			border-bottom: 4px solid #2f80ed;
		}
	}
	.account-setting {
		.profile-image {
			width: 80px;
			aspect-ratio: 1 / 1;
			border-radius: 50%;
			object-fit: cover;
		}
		&-form {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
	}
	.notifications-setting {
		width: 80%;
		margin-inline: auto;
		.form-group {
			width: max-content;
			margin: 0;
			display: grid;
			place-content: center;
		}
		label {
			margin-bottom: 0;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 22px;
			color: #333333;
			text-transform: none;
		}
		.reminders {
			margin-top: 2rem;
			margin-bottom: 4rem;
			.check-reminder {
				label {
					position: relative;
					.info {
						position: absolute;
						top: 100%;
						left: 0;
						line-height: 1;
						margin: 0;
						padding: 0;
						font-size: 14px !important;
						width: max-content;
						color: #828282;
					}
				}
			}
		}
	}

	.security-setting {
		width: 80%;
		margin-inline: auto;
		.password-setting {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 2rem;
		}
		.tfa-check-group {
			// margin-top: 2rem;
			margin-bottom: 3rem;
			.form-group {
				width: max-content;
				margin: 0;
				display: grid;
				place-content: center;
			}
			label {
				margin-bottom: 0;
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 22px;
				color: #333333;
				text-transform: none;
			}
			.check-tfa {
				label {
					position: relative;
					.info {
						position: absolute;
						top: 100%;
						left: 0;
						line-height: 1;
						margin: 0;
						padding: 0;
						font-size: 14px !important;
						width: max-content;
						color: #828282;
					}
				}
			}
		}
	}
}

// Course
.course-img {
	width: 100%;
	height: 80px;
	object-fit: cover;
}
.course-img-sm {
	width: 60px;
	height: 60px;
	object-fit: cover;
	border-radius: 8px;
}
.course-img-lg {
	width: 100%;
	height: 120px;
	object-fit: cover;
	margin-bottom: 10px;
}
.course-contents {
	.course-category {
		color: $danger;
		font-size: 16px;
		font-weight: 300;
		margin-bottom: 10px;
	}
	.course-title {
		font-size: 18px;
		font-weight: 600;
	}
	.componentCount {
		font-size: 14px;
		font-weight: 300;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
	}
}
.course-details {
	h3 {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 10px;
		color: $primary;
	}
	h5 {
		font-size: 16px;
		font-weight: 300;
		margin-bottom: 10px;
		color: $danger;
	}
}
.course-inner-img {
	width: 100%;
	// height: 320px;
	aspect-ratio: 16 / 9;
	object-fit: cover;
	margin-bottom: 10px;
	// border-radius: 8px;
}

.lessons-list {
	.lesson-single {
		display: flex;
		background-color: $white;
		border-radius: 8px;
		padding: 10px 20px;
		box-shadow: $box-shadow;
		margin-bottom: 10px;
		justify-content: space-between;
	}
	.lesson-details {
		h5 {
			font-weight: 400;
			margin-bottom: 5px;
		}
		span {
			font-size: 14px;
			color: $secondary;
		}
	}
	.active {
		// background-color: $primary;
		border: 2px solid $primary;
	}
}

.header-options {
	width: 200px;
	.form-control {
		border: 1px solid $primary;
		border-radius: 8px;
		// background-color: $primary-200;
		// color: $white;
	}
}

.tab-menus {
	background-color: $white;
	border-radius: 8px;
	display: flex;
	padding: 0px 20px;
	border: 1px solid $gray-100;

	.tab-menu-item {
		cursor: pointer;
		font-weight: 400;
		line-height: 55px;
		color: $secondary;
		&:not(:last-child) {
			margin-right: 30px;
		}
	}
	.active {
		border-bottom: 2px solid $primary;
	}
}

.right-bar {
	background-color: $white;
	border-radius: 8px;
	padding: 20px;
	box-shadow: $box-shadow;
	margin-bottom: 10px;
}

.course-detail-list {
	li {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		span {
			width: 20px;
			display: flex;
			justify-content: center;
			margin-right: 10px;
		}
	}
}

.custom-accordion {
	.card {
		border-radius: 0;
		background-color: $gray-200;
	}
}

// Progress

.component-stats-block {
	.detail {
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 5px 10px;
		.count {
			border: 2px solid #e0e8fc;
			min-width: 58px;
			height: 58px;
			text-align: center;
			line-height: 54px;
			font-size: 14px;
			border-radius: 50%;
			display: block;
			margin: 0 auto;
		}
		span {
			background: #e0e8fc;
			border-radius: 12px;
			height: 22px;
			line-height: 22px;
			width: 100%;
			display: inline-block;
			font-size: 10px;
			text-transform: uppercase;
			margin: -10px 0 0;
			font-weight: 600;
		}
	}
}

.quiz-scores {
	h2 {
		font-size: 12px;
		text-transform: uppercase;
		color: #677387;
		margin: 0 0 15px;
		font-weight: 700;
	}
	.rating {
		background: #fbefd6;
		border: 1px solid #e9be92;
		height: 32px;
		padding: 0 15px;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		max-width: 100px;
		height: 35px;
	}
}

.stats-wrapper {
	h4 {
		font-size: 12px;
		text-transform: uppercase;
		color: #677387;
		margin: 0 0 15px;
		font-weight: 700;
	}
}
.stats {
	display: flex;
	margin-bottom: 20px;
	p {
		font-size: 14px;
	}
	// span {
	//   display: block;
	//   width: 100%;
	//   background: transparent;
	//   text-align: left;
	//   font-size: 20px;
	//   padding: 0 0 0 30px;
	// }
}
