.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  -webkit-transition: all 300ms 0s ease-in-out;
  transition: all 300ms 0s ease-in-out;

  height: 100%;
  z-index: 11;
  padding-bottom: 30px;
  overflow: hidden;
  // border-right-width: 1px;
  // border-right: 2px solid $primary;

	.sidebar_inner {
		// height: calc(100% - 30px);
		padding-top: 54px;
		.simplebar-wrapper {
			overflow: hidden;
			width: inherit;
			height: inherit;
			max-width: inherit;
			max-height: inherit;
		}
	}
	.menu-items {
		span {
			margin-left: 16px;
			font-weight: 500;
		}
	}
}
.navbar__link--active {
  background-color: $primary;
  color: white !important;
  &:hover {
    color: white !important;
  }
}
