// Pagination
.pagination-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 0;
	width: 100%;
	p {
		color: #53555a;
		font-size: 14px;
		font-weight: 400;
	}
}
@media (max-width: 768px) {
	.pagination-wrapper {
		flex-direction: column;
	}
}
.pagination {
	.page-item {
		&:not(:last-child) {
			margin-right: 5px;
			border-radius: 4px;
		}
		&:hover {
			color: white;
		}
	}
	.page-link {
		border: none;
		border-radius: 4px;
		padding: 0.25rem 0.5rem;
		color: $secondary;
		display: grid;
		place-content: center;
	}
	@media (max-width: 768px) {
		.page-link {
			padding: 0.2rem 0.6rem;
		}
	}
	.active {
		.page-link {
			background-color: $primary !important;
			border-color: $primary;
			color: #fff;
		}
	}
}
