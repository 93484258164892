.previewComponent {
  width: 100%;
  flex: 1;
  padding: 56px;
  position: relative;
  @media screen and (max-width: 1024px) {
    padding: 28px;
    padding-block: 0;
  }
  @media screen and (max-width: 768px) {
    padding: 16px;
    padding-block: 0;
  }
}
.previewComponent-content {
  display: block;
  height: 100%;
  * img {
    width: 100%;
    height: 100%;
  }
  .quiz-preview-main,
  .quiz-intro {
    height: 100%;
  }

  p,
  span,
  a,
  li {
    text-align: justify;
  }
  @media screen and (max-width: 768px) {
    padding-inline: 1rem;
  }
}
.buttom-wrapper {
  position: fixed;
  bottom: 0;
  //   left: 0;
  background-color: #fff;
  align-items: center;
  height: 46px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 340px);
  border-top: 1px solid $gray-100;
}
.quiz-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  .quiz-column {
    border-radius: 10px;
    margin: 0 auto;
    max-width: 640px;
    min-width: 420px;
  }
  .intro-inner {
    padding: 20px 30px 30px;
    position: relative;
    text-align: left;
    box-shadow: 0px 3px 6px #00000029;
    background: #fff;
    border-radius: 10px;
    transition: all 0.3s ease;
    margin: 0 15px;
    .ps {
      padding: 0 20px;
      transition: all 0.3s ease;
      max-height: 492px;
    }
    .title {
      font-size: 22px;
      color: #000;
      margin: 0 0 5px 0;
      text-align: center;
    }
    .type {
      background: #eef3fe;
      border-radius: 20px;
      font-size: 12px;
      padding: 4px 18px;
      margin: 0 auto;
      text-align: center;
      display: inline-block;
      text-align: center;
    }
    svg {
      display: block;
      margin: 20px auto 5px;
    }
    .question-count {
      font-size: 15px;
      color: #000;
      font-weight: 600;
    }
    .quiz-prev-des {
      display: block;
      margin: 25px 0 0;
      padding: 0;
      text-align: left;
      max-height: 60px;
      overflow: hidden;
      transition: all 0.3s ease;
      font-size: 13px;
      line-height: 21px;
      color: #677387;
    }
    .btn-wrapper {
      width: 100%;
      margin: 0;
    }
  }
}

// Quiz Questions
.quiz-result-main-box {
  width: calc(100% - 200px);
  background: #fff;
  display: block;
  margin: 0 auto;
  padding: 20px;
  .quiz-question-type {
    font-size: 18px;
    color: #7e8288;
    font-weight: 500;
    margin: 0 0 10px 0;
    .quiz-question-marks {
      margin: 0px 0 0 5px;
      color: #848484;
      display: inline-flex;
      font-size: 12px;
      font-weight: 600;
    }
    .quiz-question-title {
      font-weight: 700;
      text-transform: inherit;
      font-size: 22px !important;
      letter-spacing: 0;
      margin-bottom: 15px;
    }
    .quiz-question-title * {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #333333;
    }
  }
}

.quiz-play-box {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  margin: 0 -10px;
}
.quiz-play-op {
  border: 1px solid #d2d2d2;
  margin: 16px 10px;
  width: 100%;
  cursor: pointer;
  transition: all linear 0.3s;
  color: #999;
  position: relative;
  border-radius: 5px;
  padding: 0.25rem 1rem;
  display: flex;
  align-items: center;
  .op-text {
    cursor: pointer;
    width: 100%;
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 0;
  }
}
.quiz-play-op.selected {
  border-color: #333333;
  // background-color: $primary;
}
.quiz-play-op.correct {
  border-color: rgba(0, 196, 140, 1);
}
.quiz-play-op.wrong {
  border-color: rgba(235, 87, 87, 1);
}
.quiz-play-op > span {
  margin-right: 10px;
}
.quiz-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #eef3fe;
  padding-bottom: 10px;
  .lesson-desc {
    color: #1d1d1d;
    font-weight: 400;
    padding: 0 15px 0 0;
    label {
      font-size: 16px;
      color: #1b1b1b;
      line-height: 17px;
      display: inline-block;
    }
    p {
      line-height: 1.4;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
.course-detail-wrapper {
  .previewComponent .slideshow-container-wrapper {
    background: #e9edf5;
    height: 100%;
    .slideshow-container {
      max-width: 980px;
      width: 100%;
      margin: 0 auto;
      background: #fff;
      height: 100%;
      padding: 0;
      .slideshow-content {
        color: #333;
        font-size: 15px;
        padding: 0;
        height: 100%;
      }
    }
  }
}
.quiz-subhead {
  padding: 15px 0 30px;
}
.quiz-intro {
  .intro-inner {
    .message {
      text-align: center;
      margin: 15px 0 0 0;
      img {
        margin: 0 auto 5px;
        width: 50px;
      }
    }
    .stats-wrapper {
      flex: 1;
      flex-wrap: wrap;
      text-align: center;
      align-content: flex-start;
      .stats {
        width: 50%;
        padding: 0 5px 15px;
        display: flex;
        flex-direction: column;
        label {
          font-size: 13px;
          font-weight: 600;
        }
        .top {
          color: $primary;
          font-size: 24px;
          font-weight: 500;
        }
        .bottom {
          font-size: 16px;
          color: #b4bfd2;
          font-weight: 500;
          margin: 0 5px 0 0;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}
.quiz-intro .intro-inner .message > span {
  color: #677387;
  font-size: 14px;
  line-height: 21px;
  display: block;
}

.ans-slot {
  margin-top: 10px;
  .title {
    background: #eef3fe;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
    margin: 0 0 10px 0;
  }
}
