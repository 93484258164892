.courseAside {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: calc(100vh - 56px) !important;
  background: #fff;
  overflow-y: scroll;
}
.course-detail-aside {
  z-index: 3;
  bottom: 0;
  padding: 15px 20px;
}
#previewHead {
  max-height: none !important;
  height: calc(100vh - 56px) !important;
}
.preview-curriculam-block {
  padding: 0 0 50px 0;
  position: relative;
}
.preview-content-inner {
  box-shadow: none;
  background: none;
  width: 100%;
  margin: 0 !important;
}
.course-sidebar-wrapper {
  width: 340px;
  margin-top: 56px;
}
.MuiDrawer-docked {
  flex: 0 0 auto;
}
.section-curriculam-heading {
  border: 0;
  //   padding: 15px 20px;
}
.course-detail-aside {
  .section-curriculam-heading {
    span {
      font-size: 12px;
      font-weight: 300;
      color: #a3abb8;
    }
    h4 {
      color: $primary;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
  }
}
.listing-component h4 {
  font-size: 12px;
  text-transform: uppercase;
  color: #677387;
  margin: 0 0 15px;
  font-weight: 700;
}

.content-title {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  line-height: 1.2;
  cursor: pointer;
  padding-left: 10px;
}
.lesson-single-list {
  //   margin-bottom: 10px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(238, 243, 254, 0.75);
  }
}
.lesson-single-list.active {
  background-color: rgba(238, 243, 254, 0.75);
}
// .course-detail-aside
//   .preview-curriculam-block
//   .listing-component
//   li
//   .has-index {
//   padding: 10px 26px 8px 50px;
//   position: relative;
//   border-radius: 5px;
//   min-height: 50px;
//   display: flex;
//   align-items: center;
// }

.component-icon {
  width: 32px;
  height: 32px;
  border: 1px solid #99a6be;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quiz-img {
  width: 32px;
  height: 32px;
}
