// Colors
$primary: #2460b9;
$secondary: #2f80ed;
$info: #385ada;
$danger: #ff647c;
$success: #00c48c;
$warning: #ffc60b;
$white: #fff;
$dark: #333;

$gray-100: #f2f2f2;
$gray-200: #e0e0e0;
$gray-300: #bdbdbd;
$gray-400: #828282;
$gray-500: #4f4f4f;
$gray-600: #333333;

$box-shadow: 0 3px 6px 0 #e9f2ff;
