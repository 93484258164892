.home-banner {
  .home-banner-inner {
    display: flex;
    .home-content-left {
      flex: 1;
      min-height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h5 {
        color: $primary;
      }
      h2 {
        color: $dark;
        font-weight: 900;
        font-size: 3rem;
        margin-bottom: 20px;
      }
      p {
        color: $secondary;
      }
      .simple_search {
        position: relative;
        max-width: 600px;
        .form-control {
          height: 58px;
          background: #ffffff;
          border-color: #f0f4f7;
          padding-left: 40px;
          border-top-left-radius: 6px !important;
          border-bottom-left-radius: 6px !important;
        }
        .ico {
          position: absolute;
          left: 15px;
          top: 20px;
          z-index: 22;
          font-size: 18px;
          color: #ccd7de;
        }
        .input-group-append {
          margin-left: -1px;
          .btn {
            background-color: $primary;
            height: 58px;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .input-group > .input-group-append > .btn,
      .input-group > .input-group-append > .input-group-text,
      .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
      .input-group
        > .input-group-prepend:first-child
        > .input-group-text:not(:first-child),
      .input-group > .input-group-prepend:not(:first-child) > .btn,
      .input-group
        > .input-group-prepend:not(:first-child)
        > .input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    .home-content-right {
      flex: 1;
      min-height: 80vh;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 60vh;
      }
    }
  }
}
