// @import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
// $jost: 'Jost', sans-serif;
// $montserrat: 'Montserrat', sans-serif;
$poppins: "Poppins", sans-serif;
$roboto: "Roboto", sans-serif;
// $oswald: "Oswald", sans-serif;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $roboto;
  font-weight: 400;
  font-size: 16px;
  width: 100vw;
  overflow-x: hidden;
}
#root {
  width: calc(100vw - 1rem);
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $poppins !important;
  font-weight: 500 !important;
  color: #333333;
}
h1 {
  font-size: 3rem !important;
  line-height: 67.2px;
}

h2 {
  font-size: 2.5rem !important;
  line-height: 56px;
}
h3 {
  font-size: 1.5rem !important;
  line-height: 33.6px;
}
h4 {
  font-size: 18px !important;
  line-height: 25.2px;
}
h5 {
  font-size: 16px !important;
  line-height: 22.4px;
}

p {
  font-family: $roboto !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.2px;
  color: #333333;
}
small {
  font-family: $roboto;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 22.4px;
}

a {
  font-family: $roboto;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 25.2 / 16;
  color: inherit;
  text-decoration: none !important;
  &:hover {
    text-decoration: none;
    color: inherit !important;
  }
}

ul,
li {
  list-style: none;
  padding-left: 0;
  font-family: $roboto;
  font-weight: 400;
  line-height: 25.2px;
  color: #828282;
}

p {
  margin-bottom: 0;
  font-family: $roboto;
}

button {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 22 / 16;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding: 0.5rem 2.5rem;
  &:disabled {
    background-color: #e0e0e0 !important;
    color: #333 !important;
    cursor: not-allowed;
    border: 1px solid #e0e0e0 !important;
    opacity: 1 !important;
    font-weight: 500;
  }
}
.form-control {
  &:focus {
    box-shadow: none;
  }
}
.card-title {
  margin-bottom: 0;
}
.pointer {
  cursor: pointer;
}

.text-small {
  font-size: 12px;
  font-weight: 300;
}

// .custom-primary {
//   background-color: $primary;
//   border: 1px solid $primary;
//   outline: none;
//   border-radius: 0;
//   color: #fff;
//   &:hover {
//     background-color: $white;
//     border: 1px solid $primary;
//     outline: none;
//     border-radius: 0;
//     color: $primary;
//   }
// }
.btn-primary {
  background-color: $primary;
  border: 1px solid $primary;
  outline: none;
  border-radius: 4px;
  color: $white;
  font-size: 14px;
  padding: 5px 30px;
  &:hover {
    background-color: lighten($primary, 10%);
    border: 1px solid $primary;
    outline: none;
    border-radius: 4px;
    color: white;
  }
}
.btn-outline-primary {
  background-color: white;
  border: 1px solid $primary;
  outline: none;
  border-radius: 4px;
  color: $primary !important;
  font-size: 14px;
  padding: 5px 30px;
  &:hover {
    background-color: $primary;
    border: 1px solid $primary;
    outline: none;
    border-radius: 4px;
    color: $white !important;
  }
  .btn-outline-danger {
    background-color: white;
    border: 1px solid $danger;
    outline: none;
    border-radius: 4px;
    color: $danger !important;
    font-size: 14px;
    padding: 5px 30px;
    &:hover {
      background-color: $danger;
      border: 1px solid $danger;
      outline: none;
      border-radius: 4px;
      color: $white !important;
    }
  }
}
.btn-sm {
  padding: 5px 30px;
  font-size: 12px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin {
  animation: spin 2s infinite forwards;
}

.cursor-pointer {
  cursor: pointer;
}
.btn-disabled {
  pointer-events: none;
  opacity: 0.6;
}
.icon-btn {
  cursor: pointer;
  transition: transform 200ms;
  &:hover {
    transform: scale(1.2);
  }
}

td {
  font-weight: 400;
}

.font-poppins {
  font-family: $poppins !important;
}

.font-roboto {
  font-family: $roboto !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

@media screen and (max-width: 768px) {
  .sm-hidden {
    display: none !important;
  }
}

.sm-visible {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.md-visible {
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
  }
}

.md-hidden {
  display: inherit;
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.custom_box_shadow {
  box-shadow: 0px 6px 15px rgba(64, 83, 145, 0.25);
}

.ratio-1x1 {
  aspect-ratio: 1 / 1;
}

.content-section {
  background: #f3f3f3;
  border-radius: 10px;
}
