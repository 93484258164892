// .content-wrapper {
// 	margin-top: 72px;
// }
.bt-danger {
  border-top: 3px solid $danger;
}
.bt-warning {
  border-top: 3px solid $warning;
}
.bt-success {
  border-top: 3px solid $success;
}
.bt-secondary {
  border-top: 3px solid $secondary;
}
.bt-purple {
  border-top: 3px solid #6756fa;
}
.bt-orange {
  border-top: 3px solid #fc8967;
}

.main-title {
  color: $primary;
  font-weight: 400;
  line-height: 1.5;
  font-size: 2rem !important;
}
.small-title {
  font-size: 14px;
  // font-weight: 300;
}
.duration {
  font-size: 12px;
  font-weight: 300;
}

.card-sm {
  background-color: $white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: $box-shadow;
}
.course-tab {
  .nav-tabs {
    border-bottom: 0;
    border-bottom: 1px solid $gray-200;
    display: flex;
    justify-content: center;
    align-items: center;
    .nav-link {
      margin-bottom: 0;
      border: none;
      cursor: pointer;
    }
    .nav-link.active {
      color: $primary;
      background-color: transparent;
      border: none;
      border-bottom: 3px solid $primary;
      font-weight: 500;
    }
  }
}
.lesson-single-list {
  &.active {
    color: $primary;
    background-color: #ddd;
    border: none;
    font-weight: 500;
  }
}
.scroll {
  height: 200px;
  overflow-y: scroll;
  padding: 10px;
}

// Drag and drop
.file-upload {
  background-color: #ffffff;
  // width: 600px;
  margin: 0 auto;
  // padding: 20px;
}

.file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: $primary;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid $primary;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: $primary;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all 0.2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  // margin-top: 20px;
  border: 2px dashed $primary;
  position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: $primary;
  border: 4px dashed #ffffff;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 100;
  // text-transform: uppercase;
  color: $primary;
  padding: 30px 0;
  font-size: 16px;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}
.card {
  border: none;
  box-shadow: $box-shadow;
  .card-header {
    background-color: $white;
    .card-title {
      color: $secondary;
      font-weight: 400;
    }
  }
}
label {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 400;
  color: #0a0a0a;
  margin-bottom: 0.3rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.list-group-item {
  border: none;
  border-bottom: 1px solid $gray-200;
}

.text-sm {
  font-size: 12px;
}

legend {
  font-size: 1rem !important;
  font-weight: 600;
}

.text-gray-400 {
  color: $gray-400 !important;
}

.setting_image_upload {
  text-transform: none;
}

.custom-dropdown-menu {
  width: max-content !important;
  @media screen and (max-width: 1024px) {
    span > div {
      width: 70px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.hover-blue {
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}

.support-img {
  height: 400px;
}

.vh-60 {
  min-height: 60vh;
}
