.password-eye {
  position: absolute;
  top: 33%;
  right: 0.5rem;
  z-index: 5;
  color: $primary;
  opacity: 0.7;
  font-size: 1rem;
  cursor: pointer;
}
