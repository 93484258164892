.header {
  position: fixed;
  top: 0;
  z-index: 990;
  width: 100%;
  .header-inner {
    height: 72px;
    display: flex;
    .header-left {
      height: 72px;
      width: 240px;
      border-bottom-width: 1px;
      border-right-width: 1px;
      background-color: #fff;
      border-bottom-color: #eee;
      padding: 0 20px;
      display: flex;
      align-items: center;
      .logo {
        height: 40px;
        margin-right: 20px;
      }
    }
    .header-right {
      height: 72px;
      width: calc(100% - 240px);
      padding: 0 30px;
      border-bottom-width: 1px;
      // background-color: rgba(249, 250, 251, 1);
      // background-color: #eee;
      background-color: #fff;
      color: #1d1929;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header-search {
        input {
          height: 44px;
          color: #393939;
          padding: 0 30px 0 40px;
          outline: 0;
          //   background: transparent;
          //   border: 1px solid transparent;
          border-radius: 2rem;
          min-width: 550px;
          font-size: 15px;
        }
      }
    }
  }
}

.preview-toolbar {
  height: 56px;
  min-height: 56px;
  padding: 5px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  background-color: $white;
}
.side-logo-wrapper {
  min-width: 340px;
  padding: 0 15px;
}
.go-back {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: #2a374a;
}
.preview-title-wrap {
  align-items: center;
  width: calc(100% - 300px);
  padding-left: 50px;
  padding-right: 50px;
}
.course-aside-heading {
  position: relative;
  align-items: center;
  padding-right: 15px;
  flex: 1;
  display: flex;
}
.course-aside-heading .course-info {
  align-items: center;
  flex: 1;
}
.course-aside-heading .image {
  // border-radius: 50%;
  height: 42px;
  width: 42px;
  border-radius: 10px;
  cursor: pointer;
  object-fit: cover;
}
.course-aside-heading h1 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0 0 0 10px;
  color: #182937;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 580px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;
}

.MuiAppBar-positionFixed {
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
}
.MuiAppBar-root {
  width: 100%;
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
}

// Landing

.landing-header {
  // box-shadow: $box-shadow;
  .navigation {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-brand {
      display: flex;
      align-items: center;
      .logo {
        height: 40px;
        margin-right: 10px;
      }
      h5 {
        font-weight: 600;
        color: $primary;
      }
    }
    .nav-menus-wrapper {
      display: flex;
      .nav-menu {
        display: flex;
        li {
          padding: 10px;
          font-size: 12px;
          a {
            font-weight: 600;
          }
        }
      }
    }
  }
}
